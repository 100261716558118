<template lang="">
  <!-- <b-card > -->
  <b-overlay
    :show="loading"
    variant="light"
    :opacity="0.5"
    rounded="sm"
  >
    <br>
    <b-table
      striped
      responsive
      :items="items"
      :fields="fieldsTable"
    >
        
      <template #cell(_)="data">
        <b-button
          v-if="data.item.vehicle_id"
          size="sm"
          variant="primary"
          class="mr-1 btn-icon"
          @click="viewForm(data)"
        >
          <feather-icon icon="FolderIcon" />
        </b-button>
      </template>
      <template #cell(duration)="data">
        <span>{{data.item.duration}} {{data.item.duration_unit == 'day'?'Hari':data.item.duration_unit == 'month'?'Bulan':'Tahun'}}</span>
      </template>
      <template #cell(price)="data">
        <span>Rp {{data.item.price|numFormat}}</span>
      </template>
      <template #cell(total_price)="data">
        <span>Rp{{ formatPrice(getTotalPrice(data.item))}}</span>
      </template>
    </b-table>
  </b-overlay>
  <!-- </b-card> -->
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import {BCard, BTable, BButton, BOverlay} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard,
    BTable,
    BButton,
    BOverlay
  },
  props:{
    spb_id:{
      type:String,
    },
    dataBilling:{
       type:Object,
    }
  },
  data() {
    return {
      dataurl:"/spb",
      baseroute:"billing-rent",
      fieldsTable: [
        { key: 'number_plate', label: 'Nopol'},
        { key: 'variant.model', label: 'Varian' },
        { key: 'duration', label: 'Lama Sewa'},
        { key: 'price', label: 'Harga'},
        { key: 'total_price', label: 'Total'},
        '_'
      ],
      items:[],
      loading:false,
    }
  },
  mounted(){
    this.fetchData()
  },
  methods:{
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    async fetchData(){
      this.loading = true
      let {data} = await this.$http.get(this.dataurl+'/'+this.spb_id+'/item')
      data = data.data
      this.$http.get(this.dataurl+'/'+this.spb_id+'/vehicle').then(res=>{
        this.loading = false
        this.items = res.data.data
        for(var i=0; i<this.items.length; i++){
          var item = data.find(x => x.variant_id == this.items[i].variant_id)
          this.items[i]['price'] = 0
          this.items[i]['duration'] = 0
          if(item){
            var reff_vehicle_duration = 0
            var reff_vehicle = this.dataBilling.reff_vehicle
            for(var j=0; j<reff_vehicle.length; j++){
              if(reff_vehicle[j].vehicle_id == this.items[i].vehicle_id){
                reff_vehicle_duration += reff_vehicle[j].duration
              }
            }
              
            this.items[i]['price'] = (parseInt(item.price)+parseInt(item.price_maintenance))
            this.items[i]['duration'] = this.dataBilling.region?reff_vehicle_duration:item.duration-reff_vehicle_duration
            this.items[i]['duration_unit'] = item.duration_unit
          }
        }
      })
      .finally(() => {this.loading = false})
    },
    viewForm(data){
      this.$router.push({ name: this.baseroute+'-maintenance',params : { vehicle_id: data.item.vehicle_id} })
    },
    getTotalPrice(result){
      var total_price = 0
      if(result.duration_unit == "day"){
        total_price = result.duration*result.price
      }else if(result.duration_unit == "month"){
        total_price = result.duration*result.price
      }else{
        total_price = 12*result.duration*result.price
      }
      return total_price
    }
  }
}
</script>
<style lang="">

</style>
