<template lang="">
  <b-overlay
    :show="loading"
    variant="light"
    :opacity="0.5"
    rounded="sm"
  >
    <br>
    <b-table
      striped
      responsive
      :items="items"
      :fields="fieldsTable"
    >
      <template #cell(index)="data">
        <span>{{data.index+1}}</span>
      </template>
      <template #cell(periode)="data">
        <span>{{data.item.month|moment("MMMM")}} {{data.item.year}}</span>
      </template>
      <template #cell(amount)="data">
        Rp {{data.item.amount|numFormat}}
      </template>
      <template #cell(ppn)="data">
        Rp {{(data.item.amount*0.1)|numFormat}}
      </template>
      <template #cell(total)="data">
        Rp {{getTotal(data.item)|numFormat}}
      </template>
      <template #cell(status)="data">
        <span :class="'badge bg-'+status[data.item.status].color">{{status[data.item.status].status}}</span>
      </template>
      <template #cell(_)="data">
        <b-button
          class="mr-1 btn-icon"
          size="sm"
          variant="primary"
          @click="viewForm(data.item)"
        >
          <feather-icon icon="FolderIcon" />
        </b-button>
      </template>
    </b-table>
  </b-overlay>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import basetable from '@/views/base/BaseTable.vue'
import {BCard, BTable, BButton, BOverlay, BRow, BCol, BFormGroup, BForm, BSpinner, BTabs, BTab} from 'bootstrap-vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import moment from 'moment'

export default {
  components: {
    ValidationObserver,
    basetable,
    BCard,
    BTable,
    BButton,
    BOverlay,
    BRow,
    BCol,
    FormVSelect,
    BFormGroup,
    BForm,
    BSpinner,
    BTabs, BTab,
  },
  props:{
    filter: {
      type: Array,
      default() {
        return []
      },
    },
    index:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      userType:null,
      user:null,
      dataurl:"/billing-detail",
      baseroute:"billing-rent",
      fieldsTable: [
        { key: 'billing.number', label: 'Billing'},
        { key: 'periode', label: 'Periode'},
        { key: 'date', label: 'Tanggal Bayar'},
        { key: 'amount', label: 'Nominal'},
        { key: 'ppn', label: 'PPN (10%)'},
        { key: 'total', label: 'Total'},
        { key: 'status', label: 'Status'},
        '_'
      ],
      items:[],
      loading:false,
      isAction:false,
      status:{
        draft:{color:"info", status:"Draft", value:"Ajukan"},
        submitted:{color:"success", status:"Diajukan", value:"Setujui"},
        approved:{color:"primary", status:"Disetujui", value:"Cek (Operasional)"},
        checkOperasional:{color:"primary", status:"Operasional Cek"},
        checkAkuntansi:{color:"success", status:"Akutansi Cek"},
        checkKeuangan:{color:"success", status:"Keuangan Cek"},
        paid:{color:"success", status:"Terbayar"},
        rejected:{color:"danger", status:"Ditolak"}
      }
    }
  },
  created() {
    if (this.$store.state.profile.info) {
      this.userType =  this.$store.state.profile.info.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$store.state.profile.info.contact
    }
    if (this.$route.meta.baseroute) this.baseroute = this.$route.meta.baseroute
  },
  mounted(){
    this.fetchData()
  },
  methods:{
    async fetchData(){
      this.loading = true
      var params = {...this.filter}
      params['filter[status]'] = this.index

      this.$http.get(this.dataurl, {params:params}).then(res=>{
        this.loading = false
        this.items = res.data.data
      })
      .finally(() => {this.loading = false})
    },
    viewForm(data){
      this.$router.push({ name: this.baseroute+'-view',params : { id: data.billing_id} })
    },
    getTotal(data){
      var total = 0
      total = ((data.amount*0.1)+parseInt(data.amount))
      if(data.penalty.length > 0){
        var total_penalty = 0
        data.penalty.forEach(penalty => {
          total_penalty += parseInt(penalty.amount)
        })
        total = total - total_penalty
      }

      return total
    }
  }
}
</script>
<style lang="">

</style>