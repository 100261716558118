<template>
  <b-card>
    <base-info
      ref="baseinfo"
      :geturl="dataurl"
      :fields="fields"
      :fields-time-line="fieldsTimeLine"
      :baseroute="baseroute"
      :isedit="false"
      :isdelete="isDelete"
      :is-action="isAction"
      @readData="showData"
    >
      <template slot="buttonaction">
        <!-- <b-button
          style="float:right;"
          class="mr-1"
          size="sm"
          variant="info"
          type="button"
          @click.prevent="actionUpload"
        >
          
          <span>Upload Berkas</span>
        </b-button> -->
        <!-- <b-button
          v-if="isAction"
          class="mr-1"
          size="sm"
          variant="info"
          type="button"
          @click.prevent="
            action(
              model.status == 'created'
                ? 'submit'
                : model.status == 'submitted'
                ? 'approve'
                : 'pay'
            )
          "
        >
          <span>{{
            model.status == 'created'
              ? 'Ajukan'
              : model.status == 'submitted'
              ? 'Setujui'
              : 'Bayar'
          }}</span>
        </b-button> -->
        <!-- <b-button
          v-if="isAction && model.status != 'paid'"
          class="mr-1"
          size="sm"
          variant="primary"
          type="button"
          @click.prevent="action(
            ((user_type == 'vendor' || user_type == 'pusat') && model.status == 'created') ? 'submit' :
            ((user_type == 'region' || user_type == 'pusat') && model.status == 'submitted') ? 'approve' :
            ((user_type == 'operasi' || user_type == 'pusat') && model.status == 'approved') ? 'checkOperasional' :
            ((user_type == 'keuangan' || user_type == 'pusat') && model.status == 'checkOperasional') ? 'checkAkuntansi' :
            ((user_type == 'vendor' || user_type == 'pusat') && model.status == 'checkAkuntansi') ? 'pay' : null
          )"
        >
          <span>
            {{
            ((user_type == 'vendor' || user_type == 'pusat') && model.status == 'created') ? 'Ajukan' :
            ((user_type == 'region' || user_type == 'pusat') && model.status == 'submitted') ? 'Setujui' :
            ((user_type == 'operasi' || user_type == 'pusat') && model.status == 'approved') ? 'Cek (Operasional)' :
            ((user_type == 'keuangan' || user_type == 'pusat') && model.status == 'checkOperasional') ? 'Cek (Akuntansi)' :
            ((user_type == 'vendor' || user_type == 'pusat') && model.status == 'checkAkuntansi') ? 'Terbayar' : null
            }}
          </span>
        </b-button> -->
        <!-- <b-button
          v-if="isCancel"
          class="mr-1"
          size="sm"
          variant="warning"
          type="button"
          @click.prevent="action(model.status == 'created' ? 'reject' : 'reject')"
        >
          <span>{{ model.status == 'created' ? 'Batalkan' : 'Tolak' }}</span>
        </b-button> -->
      </template>
    </base-info>

    <b-row>
      <b-col cols="12">
        <hr />
      </b-col>
      <b-col cols="12">
        <b-tabs>
          <b-tab active>
            <template #title>
              <feather-icon icon="TruckIcon" />
              Daftar Kendaraan
            </template>
            <vehicle v-if="model.billingable" :spb_id="model.billingable.id" :dataBilling="model" />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="FileIcon" />
              Pengajuan BAPP & BAST
            </template>
            <document v-if="model.billingable" :spb_id="model.billingable.id" :dataBilling="model" />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="FileIcon" />
              Detail Pembayaran
            </template>
            <payment v-if="model.billingable" :spb_id="model.billingable.id" :dataBilling="model" />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="TruckIcon" />
              Tracking Tagihan
            </template>
            <card-tracking-bill v-if="model.billingable" :dataBilling="model" />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCol, BRow, BTable, BOverlay, BButton, BTabs, BTab } from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfoV3.vue'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import Vehicle from '../include/IndexVehicle.vue'
import Document from '../include/IndexDocument.vue'
import Payment from '../include/IndexPayment.vue'
import CardTrackingBill from '../include/tracking/CardTrackingBill.vue'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BaseInfo,
    BTable,
    BOverlay,
    BButton,
    BTabs,
    BTab,
    Vehicle,
    Document,
    Payment,
    CardTrackingBill
  },

  data() {
    return {
      user_type: null,
      user: null,
      dataurl: '/billing',
      baseroute: 'billing-rent',
      fields: [
        { key: 'number', label: 'Nomor Penagihan', bold: true },
        { key: 'billingable.number', label: 'Nomor SPB', bold: true },
        { key: 'billingable.contract_subject', label: 'Judul Kontrak' },
        { key: 'billingable.contract_number', label: 'No Kontrak' },
        { key: 'billingable.region.name', label: 'Region' },
        { key: 'billingable.date', label: 'Tanggal Pengajuan', type: 'date' },
        {
          key: 'status',
          label: 'Status',
          type: 'spanKey',
          data: {
            created: { color: 'bg-secondary', value: 'Dibuat' },
            submitted: { color: 'bg-warning', value: 'Diajukan' },
            approved: { color: 'bg-info', value: 'Disetujui' },
            checkOperasional: { color: 'bg-primary', value: 'Dicek Operasional' },
            checkAkuntansi: { color: 'bg-info', value: 'Dicek Akuntansi' },
            paid: { color: 'bg-success', value: 'Selesai' },
            rejected: { color: 'bg-danger', value: 'Ditolak' },
          },
        },
      ],
      fieldsTimeLine: [],
      model: [],
      isDelete: false,
      isAction: false,
      isCancel: false,
    }
  },
  created() {
    if (this.$store.state.profile.info) {
      this.user_type =  this.$store.state.profile.info.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$store.state.profile.info.contact
    }
    if (this.$route.meta.baseroute) this.baseroute = this.$route.meta.baseroute
  },
  watch:{
    $route:function(newid){
      if(newid.params.reload){
        this.$refs.baseinfo.fetchData()
      }
    }
  },
  methods: {
    showData(result) {
      this.model = result

      if(result.region){
        this.fields.find(x => x.key == 'billingable.region.name').key = 'region.name'
      }


      this.fieldsTimeLine = []
      for (let i = 0; i < result.tracks.length; i++) {
        this.fieldsTimeLine.push({
          title: result.tracks[i]['description'],
          description:
            'Diproses pada ' +
            moment(result.tracks[i]['created_at']).format('DD MMMM YYYY HH:mm:ss'),
        })
      }
      this.fieldsTimeLine.is_reverse = true
      this.setButton()
    },
    setButton() {
      if (this.user_type == 'pusat') {
        this.isAction = true
        this.isCancel = this.model.status == 'submitted' ? true : false
        this.isDelete = this.model.status == 'created' ? true : false
      }else if (this.user_type == 'vendor') {
        this.isAction = this.model.status == 'created' || this.model.status == 'checkAkuntansi'
        this.isCancel = this.model.status == 'submitted' ? true : false
        this.isDelete = this.model.status == 'created' ? true : false
      }else if (this.user_type == 'region') {
        this.isAction = this.model.status == 'submitted'
        this.isCancel = false
        this.isDelete = false
      }else if (this.user_type == 'operasi') {
        this.isAction = this.model.status == 'approved'
        this.isCancel = false
        this.isDelete = false
      }else if (this.user_type == 'keuangan') {
        this.isAction = this.model.status == 'checkOperasional'
        this.isCancel = false
        this.isDelete = false
      } else {
        this.isAction = false
        this.isCancel = false
        this.isDelete = false
      }
      // console.log("Button Narko", this.isAction, this.isCancel, this.isDelete);
    },
    actionUpload() {
      this.$router.push({ name: this.baseroute + '-upload' })
    },
    action(action) {
      const id = this.$route.params.id

      this.$bvModal
        .msgBoxConfirm('Are you sure ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if (confirm) {
            this.$http.put(this.dataurl + '/' + id + '/' + action).then(() => {
              this.$store.dispatch('sendMessage', '1')

              this.$refs.baseinfo.fetchData()
            })
          }
        })
    },
  },
}
</script>
