<template lang="">
  <b-row >
    <b-col
      cols="12"
      style="align-self: center; text-align: end;"
    >
      <b-button
        size="sm"
        variant="success"
        v-b-modal="'modal-select-periode'"
        v-if="jobTitle.settings.includes('billing.rent.bappbast.request')"
      >
        <span>Tambah</span>
      </b-button>
    </b-col>
    <b-col cols="12">
      <b-overlay
        :show="loading"
        variant="light"
        :opacity="0.5"
        rounded="sm"
      >
        <br>
        <b-table
          striped
          responsive
          :items="items"
          :fields="fieldsTable"
        >
          <template #cell(index)="data">
            <span>{{data.index+1}}</span>
          </template>
          <template #cell(periode)="data">
            <span>{{data.item.periode}}</span>
          </template>
          <template #cell(status)="data">
            <span :class="'badge bg-'+status[data.item.status].color">{{status[data.item.status].status}}</span>
          </template>
          <template #cell(branch.name)="data">
            <span :class="'badge bg-'+(data.item.spb.branch_id == data.item.branch_id?'primary':'warning')">{{data.item.branch.name}}</span>
          </template>
          <template #cell(_)="data" class="center">
            <b-button
              v-if="data.item.status == 'approved'"
              class="mr-1"
              size="sm"
              variant="primary"
              @click="print('bapp', data.item)"
            >
              <feather-icon icon="PrinterIcon" /> BAPP
            </b-button>
            <b-button
              v-if="data.item.status == 'approved'"
              class="mr-1"
              size="sm"
              variant="success"
              @click="print('bast', data.item)"
            >
              <feather-icon icon="PrinterIcon" /> BAST
            </b-button>
            <b-button
              v-if="data.item.status == 'request' && jobTitle.settings.includes('billing.rent.bappbast.approve')"
              size="sm"
              class="mr-1"
              :variant="status[data.item.status].color"
              @click="updateStatus(data.item)"
            >
              <span>{{status[data.item.status].value}}</span>
            </b-button>
          </template>
        </b-table>
      </b-overlay>
    </b-col>
    <b-modal
      :id="'modal-select-periode'"
      centered
      title="Periode Pengajuan BAPP & BAST"
      hide-footer
    >
      <div>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col cols="12">
                <form-v-select
                  v-model="periode_date"
                  :selected="periode_date"
                  rules="required"
                  label="Periode Dokumen"
                  placeholder="Select Periode Dokumen"
                  :options="optionPeriode"
                />
              </b-col>
              <b-col cols="12">
                <hr>
                <b-form-group
                  label-cols-md="3"
                  label=""
                >
                  <b-button
                    style="float: right;"
                    class="mr-1"
                    size="sm"
                    variant="primary"
                    type="button"
                    @click.prevent="validationForm"
                  >
                    <span v-if="loading">
                      <b-spinner small />
                      Loading...
                    </span>
                    <span v-else>Simpan</span>
                  </b-button>
                  <b-button
                    style="float: right;"
                    class="mr-1"
                    size="sm"
                    variant="warning"
                    type="button"
                    @click.prevent="hideForm"
                  >
                    <span>Batal</span>
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </b-row>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import basetable from '@/views/base/BaseTable.vue'
import {BCard, BTable, BButton, BOverlay, BRow, BCol, BFormGroup, BForm, BSpinner, BTabs, BTab} from 'bootstrap-vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import moment from 'moment'
import DocPayment from '../include/IndexDocPayment.vue'
import VehPayment from '../include/IndexVehPayment.vue'

export default {
  components: {
    ValidationObserver,
    basetable,
    BCard,
    BTable,
    BButton,
    BOverlay,
    BRow,
    BCol,
    FormVSelect,
    BFormGroup,
    BForm,
    BSpinner,
    BTabs, BTab,
    DocPayment,
    VehPayment
  },
  props:{
    spb_id:{
      type:String,
    },
    dataBilling:{
       type:Object,
    }

  },
  data() {
    return {
      userType:null,
      user:null,
      dataurl:"/spb",
      fieldsTable: [
        { key: 'index', label: 'No'},
        { key: 'periode', label: 'Periode'},
        { key: 'status', label: 'Status'},
        { key: 'branch.name', label: 'Region'},
        '_'
      ],
      items:[],
      optionPeriode:[],
      loading:false,
      periode_date:null,
      billing_detail_id:null,
      isAction:false,
      dataFileType:null,
      status:{
        request:{color:"warning", status:"Diajukan", value:"Setujui", action:"approve"},
        approved:{color:"success", status:"Disetujui", value:""}
      },
      jobTitle: {
        settings : [],
      },
    }
  },
  created() {
    if (this.$store.state.profile.info) {
      this.userType =  this.$store.state.profile.info.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$store.state.profile.info.contact
    }
  },
  mounted(){
    this.fetchData()
    this.fetchDataPayment()
  },
  methods:{
    fetchDataPayment(){
      this.$http.get(this.dataurl+'/'+this.spb_id+'/payment').then(res=>{
        this.optionPeriode = res.data
      })
    },
    fetchData(){
      this.loading = true

      this.$http.get('auth/me').then( res => {
        for (let setting of res.data.contact.job_title.settings)
        {
          if (setting.approval && setting.module){
            this.jobTitle.settings.push(setting.module.code);
          }
        }
      })
      
      this.$http.get(this.dataurl+'/'+this.spb_id+'/document').then(res=>{
        this.loading = false
        this.items = res.data.data
      })
      .finally(() => {this.loading = false})
    },
    hideForm(){
      this.$bvModal.hide('modal-select-periode')
      this.$refs.simpleRules.reset()
    },
    validationForm(){
      this.$refs.simpleRules.validate().then( success => {
        if(success){
          this.loading = true
          this.$http.post(this.dataurl+'/'+this.spb_id+'/document', {periode:this.periode_date})
          .then(res=>{
            this.loading = false
            this.fetchDataPayment()
            this.fetchData()
            this.hideForm()
            return this.$bvToast.toast('Berhasil simpan data payment', {
              title: 'Success',
              solid: true,
              variant:'success'
            })
          })
          .catch(err=>{
            const message = err.response.data.message
            return this.$bvToast.toast(message?message:'', {
              title: 'Form Error',
              solid: true,
              variant:'danger'
            })
          })
          .finally(() => {this.loading = false})
        }else{
          return this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    updateStatus(data){
      this.$http.post(this.dataurl+'/'+this.spb_id+'/document/'+data.id+'/'+this.status[data.status].action).then(res=>{
        this.fetchData()
        return this.$bvToast.toast('Berhasil', {
              title: 'Success',
              solid: true,
              variant:'success'
            })
      })
    },
    print(document, data){
      var url = 'spb/'+this.spb_id
      
      this.$http.get(url+'/'+document+'/print', {params:{periode:data.periode, branch_id:data.branch_id}}).then(res=>{
        if(res.data.filename){
            window.open(res.data.filename)
        }
      })
      .catch(err => {
          const msg = err.response.data.message
          this.$bvToast.toast((msg)?msg:'File not found', {
            title: 'Error',
            solid: true,
            variant:'danger'
          })
      })
    },
    setMutation(data){
      return this.items.filter(item => item.periode == data.periode).length
    }
  }
}
</script>
<style lang="">

</style>
