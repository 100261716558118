<template lang="">
  <!-- <b-card > -->
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="loading"
        variant="light"
        :opacity="0.5"
        rounded="sm"
      >
        <br>
        <b-table
          striped
          responsive
          :items="items"
          :fields="fieldsTable"

        >
            
          <template #cell(_)="data">
            <b-button
              v-if="data.item.vehicle_id"
              size="sm"
              variant="primary"
              class="mr-1 btn-icon"
              @click="viewForm(data)"
            >
              <feather-icon icon="FolderIcon" />
            </b-button>
          </template>
          <template #cell(duration)="data">
            <span>{{data.item.duration}} {{data.item.duration_unit == 'day'?'Hari':data.item.duration_unit == 'month'?'Bulan':'Tahun'}}</span><br>
            <span>{{data.item.duration}} {{data.item.duration_unit == 'day'?'Hari':data.item.duration_unit == 'month'?'Bulan':'Tahun'}}</span>
          </template>
          <template #cell(price)="data">
            <span>Rp {{data.item.price|numFormat}}</span><br>
            <span>Rp {{data.item.price_maintenance|numFormat}}</span>
          </template>
          <template #cell(periode)>
            <span>{{dataBilling.periode}}</span><br>
            <span>{{dataBilling.periode}}</span>
          </template>
          <template #cell(description)="data">
            <span>{{data.item.description}}</span><br>
            <span>{{data.item.description_maintenance}}</span>
          </template>
          <template #cell(number_plate)="data">
            <span>{{data.item.number_plate}}</span><br>
            <span>{{data.item.number_plate}}</span>
          </template>
          <template #cell(variant.model)="data">
            <span>{{data.item.variant.model}}</span><br>
            <span>{{data.item.variant.model}}</span>
          </template>
        </b-table>
      </b-overlay>
    </b-col>
    <b-col cols="9" style="align-self: center; text-align: end;" v-if="!loading">
      <span>Total Tagihan Bulan {{ dataBilling.periode }} : </span>
    </b-col>
    <b-col cols="3" style="align-self: center; text-align: end;" v-if="!loading">
      <span>Rp {{total|numFormat}}</span>
    </b-col>
    <b-col cols="9" style="align-self: center; text-align: end;" v-if="!loading">
      <span>PPN (10%) : </span>
    </b-col>
    <b-col cols="3" style="align-self: center; text-align: end;" v-if="!loading">
      <span>Rp {{(total*0.1)|numFormat}}</span>
    </b-col>
    <b-col cols="9" style="align-self: center; text-align: end;" v-if="!loading && total_denda > 0">
      <span>Denda : </span>
    </b-col>
    <b-col cols="3" style="align-self: center; text-align: end;" v-if="!loading && total_denda > 0">
      <span>Rp {{total_denda|numFormat}}</span>
    </b-col>
    <b-col cols="9" style="align-self: center; text-align: end;" v-if="!loading">
      <span>Grand Total Tagihan : </span>
    </b-col>
    <b-col cols="3" style="align-self: center; text-align: end;" v-if="!loading">
      <span>Rp {{(((total*0.1)+total)-total_denda)|numFormat}}</span>
    </b-col>
  </b-row>
  <!-- </b-card> -->
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import {BCard, BTable, BButton, BOverlay, BRow, BCol} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BCard, BRow, BCol,
    basetable,
    BTable,
    BButton,
    BOverlay
  },
  props:{
    spb_id:{
      type:String,
    },
    dataBilling:{
       type:Object,
    },
    billing_detail_id:{
      type:String,
    },
  },
  data() {
    return {
      dataurl:"/spb",
      baseroute:"billing-rent",
      fieldsTable: [
        { key: 'description', label: 'Uraian'},
        { key: 'periode', label: 'Periode'},
        { key: 'number_plate', label: 'Nopol'},
        { key: 'variant.model', label: 'Varian' },
        { key: 'duration', label: 'Lama Sewa'},
        { key: 'price', label: 'Total'}
      ],
      items:[],
      loading:false,
      total:0,
      total_denda:0
    }
  },
  mounted(){
    this.fetchData()
  },
  methods:{
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    async fetchData(){
      this.loading = true
      let {data} = await this.$http.get(this.dataurl+'/'+this.spb_id+'/item')
      let penalties = await this.$http.get(this.dataurl+'/'+this.spb_id+'/penalty')
      penalties = penalties.data.data
      data = data.data
      this.$http.get(this.dataurl+'/'+this.spb_id+'/vehicle').then(res=>{
        this.loading = false
        this.items = res.data.data
        this.items = this.items.filter(x => x['received_date'] && moment(x['received_date']).format('MM YYYY') <= moment("01 "+this.dataBilling.periode).format('MM YYYY'))
        for(var i=0; i<this.items.length; i++){
          var item = data.find(x => x.variant_id == this.items[i].variant_id)
          this.items[i]['price'] = 0
          this.items[i]['duration'] = 0
          if(item && this.items[i]['received_date']){
            var reff_vehicle_duration = 0
            var reff_vehicle = this.dataBilling.reff_vehicle
            for(var j=0; j<reff_vehicle.length; j++){
              if(reff_vehicle[j].vehicle_id == this.items[i].vehicle_id){
                reff_vehicle_duration += reff_vehicle[j].duration
              }
            }
            
            var penalty = penalties.find(x => x.vehicle_id == this.items[i].vehicle_id)
            this.items[i]['penalty'] = null
            
            if(penalty){
              if(penalty.billing_detail_id == this.billing_detail_id){
                this.items[i]['penalty'] = {
                  uraian : "Denda",
                  amount : penalty.amount
                }
                this.total_denda += parseInt(penalty.amount)
              }
            }
            
            this.items[i]['description'] = item.description
            this.items[i]['price'] = item.price
            this.items[i]['price_maintenance'] = item.price_maintenance
            this.items[i]['description_maintenance'] = item.description_maintenance
            this.items[i]['duration'] = this.dataBilling.region?reff_vehicle_duration:item.duration-reff_vehicle_duration
            this.items[i]['duration_unit'] = item.duration_unit

            if(this.dataBilling.periode){
              var unit = item.duration_unit=='day'?'d':item.duration_unit=='month'?'M':'Y'
              var received_end = moment(this.items[i]['received_date']).add(this.items[i]['duration'], unit).format('DD MM YYYY')
              
              if(moment(this.items[i]['received_date']).format('MM YYYY') == moment("01 "+this.dataBilling.periode).format('MM YYYY') 
                  && moment(this.items[i]['received_date']).format('D') > 1){
                var day = moment(this.items[i]['received_date']).daysInMonth()
                var price = parseInt(item.price)
                var price_maintenance = parseInt(item.price_maintenance)
                day = parseInt(day)-parseInt(moment(this.items[i]['received_date']).format('DD'))+1;
                day = (day/moment(this.items[i]['received_date']).daysInMonth()).toFixed(2)

                this.items[i]['price'] = Math.ceil(price)*day
                this.items[i]['price_maintenance'] = Math.ceil(price_maintenance)*day
              }else if(moment(received_end).format('MM YYYY') == moment("01 "+this.dataBilling.periode).format('MM YYYY') 
                  && moment(received_end).format('D') < moment(received_end).daysInMonth()){
                var day = moment(received_end).daysInMonth()
                var price = parseInt(item.price)
                var price_maintenance = parseInt(item.price_maintenance)
                day = parseInt(day)-parseInt(moment(received_end).format('DD'))+1;
                day = (day/moment(received_end).daysInMonth()).toFixed(2)

                this.items[i]['price'] = Math.ceil(price)*day
                this.items[i]['price_maintenance'] = Math.ceil(price_maintenance)*day
              }
            }

            this.total += (parseInt(this.items[i]['price'])?parseInt(this.items[i]['price']):0)
            this.total += (parseInt(this.items[i]['price_maintenance'])?parseInt(this.items[i]['price_maintenance']):0) 
          }
        }
      })
      .finally(() => {this.loading = false})
    },
    viewForm(data){
      this.$router.push({ name: this.baseroute+'-maintenance',params : { vehicle_id: data.item.vehicle_id} })
    },
  }
}
</script>
<style lang="">

</style>
