<template lang="">
  <b-row >
    <b-col
      cols="12"
      style="align-self: center; text-align: end;"
    >
      <b-button
        size="sm"
        variant="success"
        @click.event="showForm"
      >
        <span>Tambah</span>
      </b-button>
    </b-col>
    <b-col cols="12">
      <b-overlay
        :show="loading"
        variant="light"
        :opacity="0.5"
        rounded="sm"
      >
        <br>
        <b-table
          striped
          responsive
          :items="items"
          :fields="fieldsTable"
        >
          <template #cell(index)="data">
            <span>{{data.index+1}}</span>
          </template>
          <template #cell(month)="data">
            <span>{{data.item.month|moment("MMMM")}}</span>
          </template>
          <template #cell(amount)="data">
            Rp {{data.item.amount|numFormat}}
          </template>
          <template #cell(ppn)="data">
            Rp {{(data.item.amount*0.1)|numFormat}}
          </template>
          <template #cell(total)="data">
            Rp {{getTotal(data.item)|numFormat}}
          </template>
          <template #cell(status)="data">
            <span :class="'badge bg-'+status[data.item.status].color">{{status[data.item.status].status}}</span>
          </template>
          <template #cell(_)="data" class="center">
            <b-button
              class="mr-1 btn-icon mt-1"
              size="sm"
              variant="primary"
              @click="viewForm(data.item)"
            >
              <feather-icon icon="FolderIcon" />
            </b-button>
            <b-button
              v-if="setAction(data.item) && (jobTitle.settings.includes(status[data.item.status].code) || userType == status[data.item.status].contact)"
              size="sm"
              class="mt-1"
              :variant="status[data.item.status].color"
              @click="updateStatus(data.item)"
            >
              <span>{{status[data.item.status].value}}</span>
            </b-button>
          </template>
        </b-table>
      </b-overlay>
    </b-col>
    <b-modal
      :id="'modal-select-payment'"
      centered
      title="Pembayaran"
      hide-footer
    >
      <div>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col cols="12">
                <center v-if="loading_document"><b-spinner small /></center>
                <form-v-select
                  v-else
                  v-model="periode_date"
                  :selected="periode_date"
                  item_text="periode"
                  rules="required"
                  label="Periode Bayar"
                  placeholder="Select Periode Pembayaran"
                  :options="optionPeriode"
                />
              </b-col>
              <b-col cols="12">
                <hr>
                <b-form-group
                  label-cols-md="3"
                  label=""
                >
                  <b-button
                    style="float: right;"
                    class="mr-1"
                    size="sm"
                    variant="primary"
                    type="button"
                    @click.prevent="validationForm"
                  >
                    <span v-if="loading">
                      <b-spinner small />
                      Loading...
                    </span>
                    <span v-else>Simpan</span>
                  </b-button>
                  <b-button
                    style="float: right;"
                    class="mr-1"
                    size="sm"
                    variant="warning"
                    type="button"
                    @click.prevent="hideForm"
                  >
                    <span>Batal</span>
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
    <b-modal
      :id="'modal-detail-payment'"
      centered
      title="Detail Pembayaran"
      hide-footer
      size="xl"
    >

      <b-col cols="12">
        <b-tabs>
          <b-tab active>
            <template #title>
              <feather-icon icon="TruckIcon" />
              Daftar Kendaraan
            </template>
            <veh-payment v-if="dataBilling.billingable" :spb_id="spb_id" :billing_detail_id="billing_detail_id" :dataBilling="dataBilling" />
          </b-tab>
          <b-tab v-if="billing_detail_id">
            <template #title>
              <feather-icon icon="FileIcon" />
              Daftar Dokumen
            </template>
            <doc-payment :spb_id="spb_id" :billing_detail_id="billing_detail_id" :dataBilling="dataBilling" @showData="showData" />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-modal>
  </b-row>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import basetable from '@/views/base/BaseTable.vue'
import {BCard, BTable, BButton, BOverlay, BRow, BCol, BFormGroup, BForm, BSpinner, BTabs, BTab} from 'bootstrap-vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import moment from 'moment'
import DocPayment from '../include/IndexDocPayment.vue'
import VehPayment from '../include/IndexVehPayment.vue'


export default {
  components: {
    ValidationObserver,
    basetable,
    BCard,
    BTable,
    BButton,
    BOverlay,
    BRow,
    BCol,
    FormVSelect,
    BFormGroup,
    BForm,
    BSpinner,
    BTabs, BTab,
    DocPayment,
    VehPayment
  },
  props:{
    spb_id:{
      type:String,
    },
    dataBilling:{
       type:Object,
    }

  },
  data() {
    return {
      userType:null,
      jobTitle: {
        settings : [],
      },
      user:null,
      dataurl:"/billing",
      baseroute:"billing-rent",
      fieldsTable: [
        { key: 'index', label: 'No'},
        { key: 'month', label: 'Bulan'},
        { key: 'year', label: 'Tahun' },
        { key: 'date', label: 'Tanggal Bayar'},
        { key: 'amount', label: 'Nominal'},
        { key: 'ppn', label: 'PPN (10%)'},
        { key: 'total', label: 'Total'},
        { key: 'status', label: 'Status'},
        '_'
      ],
      items:[],
      optionPeriode:[],
      loading:false,
      loading_document:false,
      periode_date:null,
      billing_detail_id:null,
      isAction:false,
      dataFileType:null,
      dataFileContact:null,
      status:{
        draft:{color:"info", status:"Draft", value:"Ajukan", action:"submit", code:'billing.submit', contact:'vendor'},
        submitted:{color:"success", status:"Diajukan", value:"Setujui", action:"approve",  code:'billing.approve', contact:''},
        approved:{color:"primary", status:"Disetujui", value:"Cek (Operasional)", action:"checkOperasional",code:'billing.checkOperasional', contact:''},
        checkOperasional:{color:"primary", status:"Operasional Cek", value:"Cek (Akutansi)", action:"checkAkuntansi", code:'billing.checkAkuntansi', contact:''},
        checkAkuntansi:{color:"success", status:"Akuntansi Cek", value:"Cek (Keuangan)", action:"checkKeuangan", code:'billing.checkKeuangan', contact:''},
        checkKeuangan:{color:"success", status:"Keuangan Cek", value:"Bayar", action:"pay", code:'billing.pay'},
        paid:{color:"success", status:"Terbayar", value:"", action:"", code:'', contact:''},
        rejected:{color:"danger", status:"Ditolak", value:"", action:"", code:'', contact:''}
      }
    }
  },
  created() {
    if (this.$store.state.profile.info) {
      this.userType =  this.$store.state.profile.info.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$store.state.profile.info.contact
    }
    if (this.$route.meta.baseroute) this.baseroute = this.$route.meta.baseroute
  },
  mounted(){
    this.fetchData()
    
  },
  methods:{
    async fetchData(){
      this.loading = true
      let {data} = await this.$http.get('file-type?filter[is_required]=true&filter[type]=spb')
      this.dataFileType = data

      if(this.spb_id){
        let spb = await this.$http.get('spb/'+this.spb_id)
        let fileContact = await this.$http.get('vendor/'+spb.data.vendor_id+'/file')
        this.dataFileContact = fileContact.data.data
      }
      

      this.$http.get('auth/me').then( res => {
        for (let setting of res.data.contact.job_title.settings)
        {
          if (setting.approval && setting.module){
            this.jobTitle.settings.push(setting.module.code);
          }
        }
      })

      this.$http.get(this.dataurl+'/'+this.$route.params.id+'/detail?sort[created_at]=asc').then(res=>{
        this.loading = false
        this.items = res.data.data
      })
      .finally(() => {this.loading = false})
    },
    async fetchDataPayment(){
      this.loading_document = true
      this.$http.get('spb/'+this.spb_id+'/document?filter[status]=approved').then(res=>{
        this.loading_document = false
        let document = []
        res.data.data.forEach(doc => {
          document.push(doc.periode)
        })
        this.optionPeriode = document
      })
    },
    viewForm(data){
      this.dataBilling.periode = moment(data.month).format("MMMM")+" "+data.year
      this.billing_detail_id = data.id
      this.$bvModal.show('modal-detail-payment')
    },
    getTotalPrice(result){
      var total_price = 0
      if(result.duration_unit == "day"){
        total_price = result.duration*result.price
      }else if(result.duration_unit == "month"){
        total_price = result.duration*result.price
      }else{
        total_price = 12*result.duration*result.price
      }
      return total_price
    },
    hideForm(){
      this.$bvModal.hide('modal-select-payment')
      this.$refs.simpleRules.reset()
    },
    showForm(){
      this.$bvModal.show('modal-select-payment')
      this.fetchDataPayment()
    },
    validationForm(){
      this.$refs.simpleRules.validate().then( success => {
        if(success){
          this.loading = true
          this.$http.post('billing-detail',{periode_date:this.periode_date, billing_id:this.$route.params.id})
          .then(res=>{
            this.loading = false
            this.fetchDataPayment()
            this.fetchData()
            this.hideForm()
            return this.$bvToast.toast('Berhasil simpan data payment', {
              title: 'Success',
              solid: true,
              variant:'success'
            })
          })
          .catch(err=>{
            const message = err.response.data.message
            return this.$bvToast.toast(message?message:'', {
              title: 'Form Error',
              solid: true,
              variant:'danger'
            })
          })
          .finally(() => {this.loading = false})
        }else{
          return this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    updateStatus(data){
      this.$http.post('billing-detail/'+data.id+'/'+this.status[data.status].action).then(res=>{
        this.fetchData()
        return this.$bvToast.toast('Berhasil', {
              title: 'Success',
              solid: true,
              variant:'success'
            })
      })
    },
    showData(data){
      this.isAction = data.is_action
      if(data.is_action)
        this.fetchData()
    },
    setAction(result){
      let action = false
      // if(result.files.length > 0){
      //   if(result.files.length >= this.dataFileType.data.length){
      //     result.files.forEach(file => {
      //       this.dataFileType.data.find(x => {
      //         if(x.id = file.file_type_id){
      //           action = true
      //         }
      //       })
      //     })
      //   }
      // }
      if(this.dataFileType.data.length > 0){
        if(result.files.length > 0){
          result.files.forEach(file => {
            if(this.dataFileType.data.find(x => x.id === file.file_type_id)){
              this.dataFileType.data.find(x => x.id === file.file_type_id)['is_exist'] = true
            }
          })
        }
        if(this.dataFileContact && this.dataFileContact.length > 0){
          this.dataFileContact.forEach(file => {
            if(this.dataFileType.data.find(x => x.id === file.file_type_id)){
              this.dataFileType.data.find(x => x.id === file.file_type_id)['is_exist'] = true
            }
          })
        }
        let dataFile = this.dataFileType.data
        dataFile = dataFile.map(value => {
          return{
            ...value,
            is_exist:value.is_exist?true:false
          }
        })
        action = true
        dataFile.forEach(file => {
          if(file.is_exist == false && file.is_required == true){
            action = false
          }
        })
      }
        
      return action
    },
    getTotal(data){
      var total = 0
      total = ((data.amount*0.1)+parseInt(data.amount))
      if(data.penalty.length > 0){
        var total_penalty = 0
        data.penalty.forEach(penalty => {
          total_penalty += parseInt(penalty.amount)
        })
        total = total - total_penalty
      }

      return total
    }
  }
}
</script>
<style lang="">

</style>
