<template>
  <b-row>
    <b-col
      cols="12"
      style="align-self: center; text-align: end;"
    >
      <b-button
        v-if="isAction"
        class="mr-1"
        size="sm"
        variant="success"
        @click="printAll"
      >
        <span>Print All</span>
      </b-button>
      <div class="float-right">
        <span
          class="badge bg-warning mr-1"
        >Max size upload 5 MB</span>
      </div>
      <!-- <b-button
        class="mr-1"
        size="sm"
        variant="success"
        @click="print('bast')"
      >
        <span>Print BAST</span>
      </b-button>
      <b-button
        size="sm"
        variant="success"
        @click="print('rikmatek')"
      >
        <span>Print Rikmatek</span>
      </b-button> -->
    </b-col>
    <b-col cols="12" v-if="dataFile">
      <basetable
        ref="tablefile"
        :dataurl="dataurl"
        :fields="fieldFile"
        :is-filter="false"
        :is-pagination="false"
        :is-view="false"
        :is-edit="false"
        :is-delete="false"
        :pageSize="null"
        @showData="showData">

        <template #cell(index)="data">
          {{data.index+1}}
        </template>
        <template #cell(is_exist)="data">
          <b-form-checkbox 
            v-model="data.item.is_exist"
            class="custom-control-primary"
            name="check-button"
            disabled
          />
        </template>
        <template #cell(_)="data">
          <b-button
            class="mr-1 btn-icon"
            size="sm"
            variant="primary"
            @click="upload(data.item)"
          >
            <feather-icon icon="UploadIcon" />
          </b-button>
          <b-form-file v-show="false" id="upload" v-model="file" @input="selectFile"></b-form-file>
          <b-button
            class="mr-1 btn-icon"
            size="sm"
            variant="success"
            @click="download(data.item)"
          >
            <feather-icon icon="DownloadIcon" />
          </b-button>
          <b-button
            v-if="data.item.related"
            class="mr-1 btn-icon"
            size="sm"
            variant="info"
            @click="print(data.item.related)"
          >
            <feather-icon icon="PrinterIcon" />
          </b-button>
        </template>

      </basetable>
      <!-- <b-col
        cols="12"
        style="align-self: center; text-align: end;"
      >
        <b-button
          v-if="isAction"
          class="mr-1 mt-1"
          size="sm"
          variant="success"
          @click="printAll"
        >
          <span>Print All</span>
        </b-button>
      </b-col> -->
    </b-col>
  </b-row>
</template>

<script>
import basetable from '@/views/base/BaseTable.vue'
import {BCard, BFormCheckbox, BButton, BFormFile, BRow, BCol} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfo.vue'

export default {
  components: {
    basetable,
    BCard,
    BFormCheckbox,
    BaseInfo,
    BButton,
    BFormFile,
    BRow, BCol
  },
  props:{
    spb_id:{
      type:String,
    },
    billing_detail_id:{
      type:String,
    },
    dataBilling:{
       type:Object,
    },
  },
  data() {
    return {
      dataurl:'/file-type?filter[type]=spb',
      baseroute:'finance-checklist',
      fields: [
        { key: 'spb.region.name', label: 'Tagihan Sewa Kendaraan Region', sortable: true },
        { key: 'spb.vendor.name', label: 'Mitra', sortable: true },
        { key: 'spb.contract_number', label: 'Surat Perjanjian / SPK', sortable: true },
        { key: 'spb.skko.number', label: 'Sumber Anggaran (SKK O/I)', sortable: true },
        { key: 'total_price', label: 'Nilai Tagihan', sortable: true, type:'number' },
        { key: 'spb.date', label: 'Periode Tagihan', sortable: true, type:'date', format:'MMMM YYYY' }
      ],
      fieldFile: [
        { key: 'index', label: 'No', sortable: true },
        { key: 'name', label: 'Uraian', sortable: true },
        { key: 'is_exist', label: 'Checklist', sortable: true},
        '_',
      ],
      isEdit:false,
      isAction:true,
      dataFile : null,
      dataFileContact:null,
      fileDetail:null,
      file:null,
      file_type:null,
      vendor_id:null,
      isAction:false,
      periode:null
    }
  },
  created(){
    if(this.dataBilling){
      this.periode = this.dataBilling.periode
    }
  },
  beforeMount(){
    this.fetchData()
  },
  methods:{
    async fetchData(){
      this.dataFile = null
      this.dataFileContact = null
      if(this.spb_id){
        let spb = await this.$http.get('spb/'+this.spb_id)
        this.vendor_id = spb.data.vendor_id
        let fileContact = await this.$http.get('vendor/'+this.vendor_id+'/file')
        this.dataFileContact = fileContact.data.data
      }
      let {data} = await this.$http.get('billing-detail/'+this.billing_detail_id+"/file?length=&sort[updated_at]=desc")
      
      this.dataFile = data
    },
    showData(result){
      if(this.dataFile.data){
        let cekDataFile = [];
        this.dataFile.data.forEach(file => {
          let selectResult = result.find(x => x.id == file.file_type_id)
          if (selectResult && !cekDataFile.includes(selectResult.name)){
            cekDataFile.push(selectResult.name)
            selectResult.file = file.file
            selectResult.file_url = file.file_url
            selectResult.is_exist = true
          }
        })
      }
      if(this.dataFileContact){
        this.dataFileContact.forEach(file => {
          let selectResult = result.find(x => x.id == file.file_type_id)
          if (selectResult && selectResult.all_invoice){
            selectResult.file = file.path
            selectResult.file_url = file.url
            selectResult.is_exist = true
          }
        })
      }
      result = result.map(value => {
        return {
          ...value,
          is_exist: value.is_exist?true:false,
        }
      })
      this.isAction = true
      result.forEach(file => {
        if(file.is_exist == false && file.is_required == true){
          this.isAction = false
        }
      })
      this.$emit("showData", {is_action:this.isAction})
      this.fileDetail = result
    },
    findItemPrice(item){
      let price = 0
      if(item.duration_unit == 'day')
        price = item.duration*item.price
      else if(item.duration_unit == 'month')
        price = (item.duration*30)*item.price
      else if(item.duration_unit == 'year')
        price = (item.duration*365)*item.price

      return price
    },
    successSubmit(){
      this.$bvToast.toast('', {
        title: 'Submit Success',
        solid: true,
        variant:'success'
      })
      this.isEdit = false
    },
    errorSubmit(err){
      const msg = !err.response?'':err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    upload(data){
      document.getElementById('upload').click()
      this.file_type = data
    },
    async selectFile(data){
      if(data){
        let formData = new FormData()
        formData.append('file', data)
        let filename = ""
        let ext = data.name?data.name.split(".").pop():""
        let url = 'upload/image'
        let arr_file = ["docx", "doc", "pdf", "xls", "xlsx"]
        if(arr_file.includes(ext) && ext == 'pdf')
          url = 'upload/file'
        else
          return this.$bvToast.toast('The file must be a file of type: pdf.', {
            title: 'Error',
            solid: true,
            variant:'danger'
          })

        let resFile = await this.$http.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}}).catch(err=>{this.errorSubmit(err)})
        if(resFile){
          if(resFile.data.path.split("/"))
            filename = resFile.data.path.split("/").pop()
          
          let params = {
            name: filename,
            file_type_id: this.file_type.id,
            file: resFile.data.path
          }

          let url = 'billing-detail/'+this.billing_detail_id
          if(this.file_type.all_invoice && this.vendor_id){
            url = 'vendor/'+this.vendor_id
          }

          this.$http.post(url+'/file', params)
          .then(()=>{
            this.successSubmit()
          })
          .catch(err=>{
            this.errorSubmit(err)
          })
          .finally(() => {this.loading = false})
        }
      }
    },
    successSubmit(){
      this.fetchData()
      this.$bvToast.toast('Berhail upload', {
        title: 'Success',
        solid: true,
        variant:'success'
      })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    download(data){
      if(data.file_url)
        window.open(data.file_url)
      else
        this.$bvToast.toast('File not found', {
          title: 'Error',
          solid: true,
          variant:'danger'
        })
    },
    print(document){
      var arr_file = ["spp", "invoice", "kwitansi"]
      var url = 'spb/'+this.spb_id
      if(arr_file.includes(document))
        url = 'billing-detail/'+this.billing_detail_id

      this.$http.get(url+'/'+document+'/print', {params:{periode:this.periode}}).then(res=>{
        if(res.data.filename){
            window.open(res.data.filename)
        }
      })
      .catch(err => {
          const msg = err.response.data.message
          this.$bvToast.toast((msg)?msg:'File not found', {
            title: 'Error',
            solid: true,
            variant:'danger'
          })
      })
    },
    printAll(){
      let file = []
      this.fileDetail = this.fileDetail.filter(x => x.file_url)
      console.log("file", this.fileDetail)
      this.fileDetail.forEach(val => file.push(val.file))
      this.$http.get('billing-detail/'+this.billing_detail_id+'/all/print', { params: { file: file }}).then(res=>{
        if(res.data.filename){
            window.open(res.data.filename)
        }
      })
      .catch(err => {
        const msg = err.response.data.message
        this.$bvToast.toast((msg)?msg:'File not found', {
          title: 'Error',
          solid: true,
          variant:'danger'
        })
      })
    }
  }
}
</script>